<template>
  <v-container class="fill-height pa-0" fluid>
    <v-data-table
      :headers="headers"
      :items="utenze"
      style="width:100%"
      height="calc(100vh - 200px)"
      :items-per-page="15"
      :search="search"
      no-results-text="Nessun risultato"
      fixed-header
      :footer-props="{
        'items-per-page-options': [15, 25, 50, -1],
        'items-per-page-text': 'Elementi per pagina',
        'items-per-page-all-text': 'Tutti',
      }"
      class="mt-3"
      :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            label="Cerca"
            prepend-inner-icon="mdi-magnify"
            clearable
            hide-details
          ></v-text-field>

          <v-spacer></v-spacer>

          <v-btn
            v-blur
            fab
            small
            color="primary"
            @click="openAggiungiUtenza"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      
      <template v-slot:[`item.ultimo_accesso`]="{ item }">
        {{ item.ultimo_accesso ? new Date(item.ultimo_accesso).toLocaleString() : "" }}
      </template>

      <template v-slot:[`item.tipo`]="{ item }">
        {{ getPermessoValue(item.tipo) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div v-if="item.stato == 1">
          <v-icon small class="mr-2" @click="openModificaUtenza(item)">
            mdi-pencil
          </v-icon>
          <v-icon small class="mr-2" @click="openModificaStatoUtenza(item, 2)"> 
            mdi-cancel 
          </v-icon>
          <v-icon small @click="openModificaStatoUtenza(item, 0)"> 
            mdi-delete 
          </v-icon>
        </div>
        <div v-else>
          <v-btn
            color="grey lighten-2"
            @click="openModificaStatoUtenza(item, 1)"
            elevation="0"
          >
            Attiva
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <v-dialog v-model="utenza_dialog" max-width="800px" persistent>
      <AggiungiModificaUtenza v-model="utenza_edit" ref="utenza_dialog" @close-dialog="closeDialog" @update-utenze="getUtenze"/>
    </v-dialog>

    <v-dialog v-model="utenza_dialog_delete" max-width="500px" persistent>
      <v-card style="text-align: center;">
        <v-card-title class="text-h5" style="justify-content: center;">Attenzione</v-card-title>
        <v-card-text class="pb-0">
          <span class="black--text text-subtitle-1">Sei sicuro di voler {{ utenza_delete["stato"] == 2 ? "disabilitare" : utenza_delete["stato"] == 1 ? "abilitare" : "cancellare" }} l'utenza <b>{{ utenza_delete["username"]}}</b>?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="secondary" 
            text 
            @click="closeDialog"
          >
            Annulla
          </v-btn>
          <v-btn 
            color="primary" 
            text 
            @click="modificaStatoUtenza"
            :loading="utenza_dialog_delete_loading"
            :disabled="utenza_dialog_delete_loading"
            >
            Conferma
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AggiungiModificaUtenza from '../components/AggiungiModificaUtenza.vue'

export default {
  data: () => ({
    utenze: [],
    search: "",
    loading: false,
    utenza_dialog: false,
    utenza_dialog_delete: false,
    utenza_dialog_delete_loading: false,
    utenza_edit: {},
    utenza_delete: {},
    utenza_edit_default: {
      id_cliente: null,
      email: "",
      username: "",
      password: "",
      nome: "",
      telefono: "",
      tipo: null
    },
    headers: [
      {
        text: "Username",
        value: "username",
      },
      {
        text: "Email",
        value: "email",
      },
      {
        text: "Nome",
        value: "nome",
      },
      {
        text: "Cliente",
        value: "rag_soc_cliente",
      },
      {
        text: "Tipo",
        value: "tipo",
      },
      {
        text: "Telefono",
        value: "telefono",
      },
      {
        text: "Pass. Prov.",
        value: "psw_provvisoria",
      },
      {
        text: "Ultimo accesso",
        value: "ultimo_accesso",
      },
      { text: "Azioni", value: "actions", sortable: false, align: "center"},
    ],
  }),
  components: {
    AggiungiModificaUtenza
  },
  mounted() {
    this.getUtenze()

    if (!this.$store.state.clienti || this.$store.state.clienti.length == 0) {
      this.$store.dispatch("getClienti")
    }

    if (!this.$store.state.vettori || this.$store.state.vettori.length == 0) {
      this.$store.dispatch("getVettori")
    }
  },
  methods: {
    getPermessoValue(tipo) {
      if (this.$store.state.permessi_array) {
        var permesso = this.$store.state.permessi_array.filter((el) => el.id == tipo)[0]

        if (permesso && permesso["text"]) {
          return permesso["text"] 
        }
      }
      return ""
    },
    getUtenze() {
      this.loading = true
      this.axios
        .get(`${this.$store.state.endpoint}/utenze`)
        .then((response) => {
          this.utenze = response.data
        })
        .catch((e) => {
          this.$store.state.snackbar_text = `Non è stato possibile scaricare i dati relativi alle utenze`

          if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
            this.$store.state.snackbar_text += ": " + e.response.data.message
          }
          else {
            this.$store.state.snackbar_text += ". Verifica la connessione internet o riprova più tardi"
          }
            
          this.$store.state.snackbar = true
          this.$store.state.snackbar_color = "error"
          console.log("Errore comparti", e)
        })
        .finally(() => {
          this.loading = false
        });
    },
    closeDialog() {
      this.utenza_dialog = false
      this.utenza_dialog_delete = false
      this.utenza_edit = Object.assign({}, this.utenza_edit_default)
      this.utenza_delete = Object.assign({}, this.utenza_edit_default)
    },
    openAggiungiUtenza() {
      this.utenza_edit = Object.assign({}, this.utenza_edit_default)
      this.utenza_edit["nuova_utenza"] = true

      //  Blocco la scelta del tipo di utenza e del cliente / vettore ai master
      if (this.$store.state.permessi == 4) {
        this.utenza_edit["tipo"] = 2
        this.utenza_edit.id_cliente = this.$store.state.clienti[0]["id_cliente"]
      }
      else if (this.$store.state.permessi == 3) {
        this.utenza_edit["tipo"] = 1
        this.utenza_edit.id_cliente = this.$store.state.vettori[0]["id_cliente"]
      }

      this.utenza_dialog = true
    },
    openModificaUtenza(item) {
      this.utenza_edit = Object.assign({}, item)
      this.utenza_edit["nuova_utenza"] = false
      this.utenza_dialog = true
    },
    openModificaStatoUtenza(item, stato) {
      this.utenza_delete = Object.assign({}, item)
      this.utenza_delete["stato"] = stato
      this.utenza_dialog_delete = true
    },
    modificaStatoUtenza() {
      this.utenza_dialog_delete_loading = true

      var params = {
        id_utenza: this.utenza_delete.id_utenza,
        stato: this.utenza_delete.stato,
      }

      var dicitura = ""
        switch (params.stato) {
          case 0:
            dicitura = "elimin"
            break;
          case 1:
            dicitura = "abilit"
            break;
          case 2:
            dicitura = "disabilit"
            break;
        
          default:
            dicitura = "elimin"
            break;
        }

      this.axios
        .delete(`${this.$store.state.endpoint}/utenze?${new URLSearchParams(params).toString()}`)
        .then(() => {
          this.$store.state.snackbar = true
          this.$store.state.snackbar_text = `Utenza ${dicitura}ata con successo!`
          this.$store.state.snackbar_color = "success"

          this.getUtenze()
          this.closeDialog()
        })
        .catch((e) => {
          this.$store.state.snackbar_text = `Non è stato possibile ${dicitura}are l'utenza`

          if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string' ) {
            this.$store.state.snackbar_text += ": " + e.response.data.message
          }
          else {
            this.$store.state.snackbar_text += ". Verifica la connessione internet o riprova più tardi"
          }
            
          this.$store.state.snackbar = true
          this.$store.state.snackbar_color = "error"
          console.log("Errore utenza", e)
        })
        .finally(() => {
          this.utenza_dialog_delete_loading = false
        });
    }
  }
}
</script>

<style>

</style>